<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalEmpresa">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ estado ? "Crear" : "Editar" }} Empresa
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="nombre"
                    :class="errorNombre ? 'error' : ''"
                  />
                  <div class="invalid-feedback d-block" v-if="errorNombre">
                    {{ errorNombreMessage }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="inputCity">
                    Logo
                    <small>(300 x 300)</small>*
                  </label>
                  <div class="custom-file">
                    <input
                      type="file"
                      id="logo"
                      class="custom-file-input"
                      @change="uploadFile"
                    />
                    <label
                      class="custom-file-label"
                      for="customFileLang"
                      data-browse="Cargar archivo"
                      :class="errorFile ? 'error' : ''"
                    >
                      {{
                        nombreFile == "" ? "Seleccionar Archivo" : nombreFile
                      }}
                    </label>
                  </div>
                  <div class="invalid-feedback d-block" v-if="errorFile">
                    {{ erroFileMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelar"
              :disabled="guardar"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="submit"
              v-if="!guardar"
            >
              Guardar
            </button>
            <button class="btn btn-success" type="button" disabled v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Guardando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  data() {
    return {
      id: "",
      nombre: "",
      nombreFile: "",
      errorNombre: false,
      errorNombreMessage: "",
      errorFile: false,
      erroFileMessage: "",
      dataFile: "",
      estado: true,
      guardar: false,
    };
  },
  watch: {
    nombre() {
      if (this.nombre != "") {
        this.errorNombre = false;
        this.errorNombreMessage = "";
      }
    },
    nombreFile() {
      if (this.nombreFile != "") {
        this.errorFile = false;
        this.erroFileMessage = "";
      }
    },
  },
  methods: {
    open(datos) {
      if (datos.id != undefined) {
        this.id = datos.id;
        this.nombre = datos.nombre;
        this.estado = false;
      }
      $("#modalEmpresa").modal("toggle");
    },
    async submit() {
      if (this.valid()) {
        this.guardar = true;
        if (this.id == "") {
          let datos = {
            nombre: this.nombre,
            archivo: this.dataFile,
          };
          await this.$store.dispatch("setEmpresa", datos);
          await this.$store.dispatch("fetchEmpresas");
          this.cancelar();
        } else {
          let datos = {
            id: this.id,
            nombre: this.nombre,
            archivo: this.dataFile != "" ? this.dataFile : "",
            _method: "put",
          };
          await this.$store.dispatch("updateEmpresa", datos);
          const data = await this.$store.dispatch("fetchEmpresas");
          if (data) {
            this.$emit("actualizar", datos);
          }
          this.cancelar();
        }
      }
    },
    uploadFile(e) {
      let posicion = e.target.files[0].name.indexOf(".");
      let extension = e.target.files[0].name.substring(posicion + 1);
      let valido = ["png", "jpg", "jpeg"];
      var reader = new FileReader();
      var file = e.target.files[0];
      let element = this;
      reader.readAsDataURL(file);
      reader.addEventListener("load", function () {
        var image = new Image();
        image.src = reader.result;
        image.addEventListener("load", function () {
          if (image.width != 300 && image.height != 300) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "info",
              title: "La medida de la imagen debe ser de 300x300",
            });
            return false;
          }

          if (/\.(jpg|png|jpeg)$/i.test(e.target.files[0].name)) {
            element.nombreFile = e.target.files[0].name;
            element.dataFile = e.target.files[0];
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "No es una imagen.",
            });
          }
        });
      });
    },
    valid() {
      let contador = 0;
      if (this.nombre == "") {
        this.errorNombre = true;
        this.errorNombreMessage = "Ingrese el nombre de la empresa";
        contador++;
      }
      if (this.nombreFile == "" && this.estado == true) {
        this.errorFile = true;
        this.erroFileMessage = "Debes cargar el logo ";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    limpiar() {
      this.id = "";
      this.nombre = "";
      this.nombreFile = "";
      this.errorNombre = false;
      this.errorNombreMessage = "";
      this.errorFile = false;
      this.erroFileMessage = "";
      this.dataFile = "";
      this.estado = true;
      this.guardar = false;
      $("#logo").val("");
    },
    cancelar() {
      let datos = {
        id: this.id,
        nombre: this.nombre,
      };
      this.$emit("actualizar", datos);
      this.limpiar();
      $("#modalEmpresa").modal("toggle");
    },
  },
};
</script>
