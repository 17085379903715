<template>
  <section>
    <div class="row">
      <MenuTaller />
      <loading class="col-lg-10 p-0" v-if="estadoloading" />
      <div class="col-lg-10 d-flex justify-content-center" v-else>
        <div class="col-lg-9">
          <h5 class="text-holistica text-center pt-3 pb-3">Editar Taller</h5>
          <form @submit.prevent="guardar">
            <div class="form-row">
              <div class="form-group col-md-7">
                <label for="inputEmail4">Tipo de taller *</label>
                <v_select
                  @input="myActionTaller"
                  placeholder="Seleccione un tipo de taller"
                  label="nombre"
                  :options="tipoTalleres"
                  v-model="tipo_taller"
                  :class="errorTipoTaller ? 'error' : ''"
                />
                <div class="invalid-feedback d-block" v-if="errorTipoTaller">
                  {{ messageErrorTipoTaller }}
                </div>
              </div>
              <div class="form-group col-md-5">
                <label for="inputPassword4">
                  Empresa *
                  <span @click="open_modal_empresa">
                    <font-awesome-icon
                      icon="plus-circle"
                      class="mx-2 text-primary cursor-pointer"
                    />
                  </span>
                  <span
                    v-if="empresa"
                    @click="open_modal_edit_empresa(empresa)"
                  >
                    <font-awesome-icon
                      icon="pencil-alt"
                      class="text-secondary cursor-pointer"
                    />
                  </span>
                </label>
                <v_select
                  @input="myActionEmpresa"
                  placeholder="Seleccione una empresa"
                  label="nombre"
                  :options="empresas"
                  v-model="empresa"
                  :class="errorEmpresa ? 'error' : ''"
                />
                <div class="invalid-feedback d-block" v-if="errorEmpresa">
                  {{ messageErrorEmpresa }}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputZip">Fecha *</label>
                <datePicker
                  :config="options"
                  v-model="fecha"
                  :class="errorFecha ? 'error' : ''"
                />
                <div class="invalid-feedback d-block" v-if="errorFecha">
                  {{ messageErrorFecha }}
                </div>
              </div>
              <div class="form-group col-md-4">
                <label for="inputZip">Hora *</label>
                <VueTimepicker
                  format="hh:mm A"
                  v-model="hora"
                  :class="errorHora ? 'error' : ''"
                />
                <div class="invalid-feedback d-block" v-if="errorHora">
                  {{ messageErrorHora }}
                </div>
              </div>
              <div class="form-group col-md-4">
                <label for="inputState">
                  Ciudad *
                  <span @click="open_modal_ciudad">
                    <font-awesome-icon
                      icon="plus-circle"
                      class="mx-2 text-primary cursor-pointer"
                    />
                  </span>
                  <span v-if="ciudad" @click="open_modal_edit_ciudad(ciudad)">
                    <font-awesome-icon
                      icon="pencil-alt"
                      class="text-secondary cursor-pointer"
                    />
                  </span>
                </label>
                <v_select
                  @input="myActionCiudad"
                  placeholder="Seleccione una ciudad"
                  label="nombre"
                  :options="ciudades"
                  v-model="ciudad"
                  :class="errorCiudad ? 'error' : ''"
                />
                <div class="invalid-feedback d-block" v-if="errorCiudad">
                  {{ messageErrorCiudad }}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputCity">Cantidad de participantes</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputCity"
                  disabled
                  v-model="cantidad"
                />
              </div>
              <div class="form-group col-md-8">
                <label for="inputCity">Archivo *</label>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    @change="uploadFile"
                  />
                  <label
                    class="custom-file-label"
                    for="customFileLang"
                    data-browse="Cargar archivo"
                    :class="errorArchivo ? 'error' : ''"
                    >{{
                      nombreFile == "" ? "Seleccionar Archivo" : nombreFile
                    }}</label
                  >
                </div>
                <div class="invalid-feedback d-block" v-if="errorArchivo">
                  {{ messageErrorARchivo }}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="iputObjetivo">Objetivo *</label>
                <!-- <ckeditor
                  :editor="editor"
                  v-model="objetivo"
                  :config="editorConfig"
                  @ready="Prueba"
                ></ckeditor> -->
                <vue-editor v-model="objetivo"></vue-editor>
                <div class="invalid-feedback d-block" v-if="errorObjetivo">
                  {{ messageErrorObjetivo }}
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="inputCity">Formadores *</label>
                <v_select
                  placeholder="Seleccione los formadores"
                  multiple
                  :options="formadores"
                  label="fullname"
                  v-model="list_formadores"
                  :selectable="() => list_formadores.length < 4"
                  :class="errorFormador ? 'error' : ''"
                >
                  <div slot="no-options">No hay opciones</div>
                </v_select>
                <div class="invalid-feedback d-block" v-if="errorFormador">
                  {{ messageErrorFormador }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-secondary btn-sm mr-1"
                @click.prevent="cancelar()"
              >
                Cancelar
              </button>
              <button
                type="submit"
                class="btn btn-success btn-sm"
                v-if="estado"
              >
                Guardar
              </button>
              <button
                class="btn btn-success btn-sm"
                type="button"
                disabled
                v-else
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Creando...
              </button>
            </div>
          </form>
        </div>
      </div>
      <modalEmpresa ref="modalEmpresa" @actualizar="actualizar_empresa" />
      <modalCiudad ref="modalCiudad" @actualizar="cambiar" />
    </div>
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import loading from "@/components/Loading";
import v_select from "vue-select";
import "vue-select/dist/vue-select.css";
// Import required dependencies
import "bootstrap/dist/css/bootstrap.css";
// Import this component
import datePicker from "vue-bootstrap-datetimepicker";
import { en, es } from "vuejs-datepicker/dist/locale";
// Import date picker css
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
// CSS
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import axios from "axios";
import MenuTaller from "@/components/MenuTaller";
import modalEmpresa from "@/components/modalEmpresa";
import modalCiudad from "@/components/modalCiudad";
import { VueEditor } from "vue2-editor";
export default {
  name: "talleres",
  data() {
    return {
      tipo_taller: "",
      empresa: "",
      ciudad: "",
      fecha: "",
      hora: "",
      cantidad: "",
      objetivo: "",
      copy_observer: "",
      nombreFile: "",
      id: "",
      list_formadores: [],
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        locale: "es",
      },
      estado: true,
      messageErrorObjetivo: "Dejanos saber cual es el objetivo del taller.",
      messageErrorFormador: "Selecciones a los formadores del taller.",
      messageErrorARchivo: "Debes que subir el listado de participantes.",
      messageErrorCiudad: "En que ciudad se va a realizar el taller.?",
      messageErrorHora: "A que hora se realizara el taller.",
      messageErrorFecha: "Que día va a realizar el taller.?",
      messageErrorEmpresa: "A que empresa desea realizar el taller.?",
      messageErrorTipoTaller:
        "Dejanos saber que tipo de taller quieres realizar.",
      errorFormador: false,
      errorArchivo: false,
      errorCiudad: false,
      errorHora: false,
      errorFecha: false,
      errorEmpresa: false,
      errorTipoTaller: false,
      errorObjetivo: false,
      reset: "",
    };
  },
  components: {
    v_select,
    datePicker,
    VueTimepicker,
    loading,
    MenuTaller,
    modalEmpresa,
    modalCiudad,
    VueEditor,
  },
  watch: {
    tipo_taller() {
      if (this.tipo_taller != "") {
        this.errorTipoTaller = false;
      }
    },
    empresa() {
      if (this.empresa != "") {
        this.errorEmpresa = false;
      }
    },
    fecha() {
      if (this.fecha != "") {
        this.errorFecha = false;
      }
    },
    hora() {
      if (this.hora != "") {
        this.errorHora = false;
      }
    },
    objetivo(val) {
      if (this.objetivo != "") {
        this.errorObjetivo = false;
      }
    },
    ciudad() {
      if (this.ciudad != "") {
        this.errorCiudad = false;
      }
    },
    nombreFile() {
      if (this.nombreFile != "") {
        this.errorArchivo = false;
      }
    },
    list_formadores() {
      if (this.list_formadores.length > 0) {
        this.errorFormador = false;
      }
    },
  },
  methods: {
    guardar() {
      if (this.validar()) {
        this.estado = false;
        let params = {
          cantidadPersona: this.cantidad,
          fecha: moment(this.fecha).format("YYYY-MM-DD"),
          hora: this.hora,
          objetivo: this.objetivo,
          tipo_id: this.tipo_taller.id,
          ciudad_id: this.ciudad.id,
          empresa_id: this.empresa.id,
          nombre_archivo: this.nombreFile,
          formadores: JSON.stringify(this.list_formadores),
          id: this.id,
          _method: "put",
        };
        this.$store.dispatch("updateTaller", params).then((response) => {
          if (response.STATUS_CODE == 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Se actualizo el taller correctamente.",
            });
            this.$router.push({ name: "talleres" });
            this.limpiar();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Error al actualizar el taller",
            });
            this.estado = true;
          }
        });
      }
    },
    uploadFile(e) {
      let posicion = e.target.files[0].name.indexOf(".");
      let extension = e.target.files[0].name.substring(posicion + 1);
      if (extension == "xlsx" || extension == "xls") {
        this.estado = false;
        var data = new FormData();
        data.append("archivo", e.target.files[0]);
        data.append("token", this.$store.getters.getUserAuth.access_token);
        var request = new XMLHttpRequest();
        let that = this;
        request.onreadystatechange = function () {
          if (request.readyState == 4) {
            if (request.status >= 300) {
              console.log("Error");
            } else {
              let result = JSON.parse(request.response);
              if (result.STATUS_CODE == 1) {
                that.nombreFile = result.DATA.nombre;
                that.cantidad = result.DATA.cantidadPersonas;
                that.estado = true;
              }
            }
          }
        };
        request.open(
          "POST",
          process.env.VUE_APP_WEBSERVICE_URL + "/uploadFile"
        );
        // Send the request
        request.send(data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "No es un archivo de excel",
        });
      }
    },
    validar() {
      let contador = 0;
      if (this.id == "") {
        if (this.nombreFile == "") {
          contador++;
          this.errorArchivo = true;
        }
      }
      if (this.tipo_taller == "") {
        contador++;
        this.errorTipoTaller = true;
      }

      if (this.empresa == "") {
        contador++;
        this.errorEmpresa = true;
      }

      if (this.fecha == "") {
        contador++;
        this.errorFecha = true;
      }

      if (this.hora == "") {
        contador++;
        this.errorHora = true;
      }

      if (this.objetivo == "") {
        contador++;
        this.errorObjetivo = true;
      }

      if (this.ciudad == "") {
        contador++;
        this.errorCiudad = true;
      }

      if (this.list_formadores.length == 0) {
        contador++;
        this.errorFormador = true;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    cancelar() {
      this.limpiar();
      this.$router.push({ name: "talleres" });
    },
    limpiar() {
      this.tipo_taller = "";
      this.empresa = "";
      this.ciudad = "";
      this.fecha = "";
      this.hora = "";
      this.objetivo = "";
      this.cantidad = "";
      this.nombreFile = "";
      this.list_formadores = [];
      this.estado = true;
      this.errorFormador = false;
      this.errorArchivo = false;
      this.errorCiudad = false;
      this.errorHora = false;
      this.errorFecha = false;
      this.errorObjetivo = false;
      this.errorEmpresa = false;
      this.errorTipoTaller = false;
      this.id = "";
    },
    editar(id) {
      this.$store.commit("setEstatusLoading");

      this.$store.dispatch("fetchTaller", id).then((response) => {
        if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
          const element = response.DATA;
          this.tipo_taller = element.tipoTaller;
          this.empresa = element.empresa;
          this.ciudad = element.ciudad;
          this.fecha = element.fecha;
          this.hora = element.hora;
          this.objetivo = element.objetivo;
          this.id = element.id;
          this.list_formadores = element.formadores;
        }
      });
    },
    open_modal_empresa() {
      this.$refs.modalEmpresa.open({});
    },
    open_modal_edit_empresa(dato) {
      let datos = {
        id: dato.id,
        nombre: dato.nombre,
        foto: "",
      };
      this.empresa = "";
      this.$refs.modalEmpresa.open(datos);
    },
    open_modal_ciudad() {
      this.$refs.modalCiudad.open({});
    },
    open_modal_edit_ciudad(dato) {
      let datos = {
        id: dato.id,
        nombre: dato.nombre,
      };
      this.ciudad = "";
      this.$refs.modalCiudad.open(datos);
    },
    cambiar(e) {
      this.ciudad = e;
    },
    actualizar_empresa(e) {
      this.empresa = e;
    },
    Prueba() {
      this.objetivo = this.copy_observer;
    },
    myActionTaller: function (e) {
      if (!e) {
        this.tipo_taller = "";
      }
    },
    myActionEmpresa: function (e) {
      if (!e) {
        this.empresa = "";
      }
    },
    myActionCiudad: function (e) {
      if (!e) {
        this.ciudad = "";
      }
    },
  },
  computed: {
    ciudades() {
      return this.$store.getters.getCiudades;
    },
    empresas() {
      return this.$store.getters.getEmpresas;
    },
    tipoTalleres() {
      return this.$store.getters.getTipoTalleres;
    },
    formadores() {
      return this.$store.getters.getFormadores;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchCiudades");
    await this.$store.dispatch("fetchEmpresas");
    await this.$store.dispatch("fetchTipoTalleres");
    this.$store
      .dispatch("fetchTaller", this.$route.params.id)
      .then((response) => {
        if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
          const element = response.DATA;
          this.tipo_taller = element.tipoTaller;
          this.empresa = element.empresa;
          this.ciudad = element.ciudad;
          this.fecha = element.fecha;
          this.hora = element.hora;
          this.objetivo = element.objetivo;
          this.id = element.id;
          this.list_formadores = element.formadores;
        }
      });
  },
};
</script>
<style lang="css">
.error > .vs__dropdown-toggle,
.error > .display-time,
.error {
  border-color: red !important;
}

.vue__time-picker input.display-time {
  width: 100%;
}

.vue__time-picker {
  width: 100%;
}

.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
