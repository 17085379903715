<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalCiudad">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ estado ? "Crear" : "Editar" }} Ciudad</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="nombre"
                    :class="errorNombre ? 'error' : ''"
                  />
                  <div class="invalid-feedback d-block" v-if="errorNombre">{{ errorNombreMessage }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelar"
              :disabled="guardar"
            >Cancelar</button>
            <button type="button" class="btn btn-success" @click="submit" v-if="!guardar">Guardar</button>
            <button class="btn btn-success" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Guardando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      nombre: "",
      errorNombre: false,
      errorNombreMessage: "",
      estado: true,
      guardar: false,
    };
  },
  watch: {
    nombre() {
      if (this.nombre != "") {
        this.errorNombre = false;
        this.errorNombreMessage = "";
      }
    },
  },
  methods: {
    open(datos) {
      if (datos.id != undefined) {
        this.id = datos.id;
        this.nombre = datos.nombre;
        this.estado = false;
      } else {
        this.limpiar();
      }
      $("#modalCiudad").modal("toggle");
    },
    async submit() {
      if (this.valid()) {
        this.guardar = true;
        if (this.id == "") {
          let datos = {
            id: this.id,
            nombre: this.nombre,
          };
          await this.$store.dispatch("setCiudad", datos);
          await this.$store.dispatch("fetchCiudades");
          this.cancelar();
        } else {
          let datos = {
            id: this.id,
            nombre: this.nombre,
            _method: "put",
          };
          await this.$store.dispatch("updateCiudad", datos);
          const data = await this.$store.dispatch("fetchCiudades");
          if (data) {
            this.$emit("actualizar", { id: this.id, nombre: this.nombre });
          }
          $("#modalCiudad").modal("toggle");
        }
      }
    },
    valid() {
      let contador = 0;
      if (this.nombre == "") {
        this.errorNombre = true;
        this.errorNombreMessage = "Ingrese el nombre de la ciudad";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    limpiar() {
      this.id = "";
      this.nombre = "";
      this.errorNombre = false;
      this.errorNombreMessage = "";
      this.estado = true;
      this.guardar = false;
    },
    cancelar() {
      let datos = {
        id: this.id,
        nombre: this.nombre,
      };
      this.$emit("actualizar", datos);
      this.limpiar();
      $("#modalCiudad").modal("toggle");
    },
  },
};
</script>
